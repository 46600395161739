@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Inter:700|Poppins:400');


  body {
    color: #e0e9ee;
    background: #080b12;
  }


  .project-container {
    margin: auto;
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.card-container {
    display: block;
    margin: 10px;
    background-color: var(--card-box-color);
    border-radius: 20px;
    width: 300px;
    height: 400px;
    min-width: 300px;
    min-height: 400px;
    transition: transform 250ms;
    overflow: hidden;
    position: relative;
    display: block;
}
.redirect-icon {
    width: 50px;
    height: 50px;
    background-color: rgba(0,0,0,0);
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
    color: var(--accent-color);
    opacity: 0;
    transition: opacity 250ms;
}
.card-container:hover {
    transform: translateY(-10px);

    .redirect-icon {
        opacity: 1;
    }

    .card-description {
        top: 62.5%;
    }

    img {
        transform: scale(1.2);
    }
}

.fill-div {
    display: block;
    position: absolute;
    z-index: 2;
    height: 400px;
    width: 300px; 
}

.card-image {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 250ms;
        position: relative;
        z-index: 0;
    }
}

.card-description {
    background-color: rgba(77, 77, 77, 0.9);
    transition: top 250ms;
    width: 100%;
    height: 37.5%;
    position: absolute;
    top: 76.5625%;
    z-index: 1;
}

.card-header {
    position: absolute;
    z-index: 1;
    color: var(--card-header-color);
    font-size: 20px;
    text-align: left;
    width: calc(100% - 20px);
    height: 25%;
    margin-left: 10px;
    padding-top: 5px;
}

.card-description-text {
    position: absolute;
    z-index: 1;
    color: var(--card-description-color);
    padding-left: 10px;
    font-size: 16px;
    top: 25%;
}

.card-bubble-container {
    width: 100%;
    height: 12.5%;
    position: absolute;
    left: 0;
    top: 62.5%;
    padding: 10px;
    display: inline-flex;
    padding-right: 20px;
    padding-left: 20px;
}

.card-bubble {
    width: fit-content;
    height: fit-content;
    font-size: 12px;
    min-width: 75px;
    padding: 7px;
    margin-bottom: 2px;
    margin-right: 17px;
    border-radius: 20px;
    background-color: var(--card-bubble-color);
    text-align: center;
}